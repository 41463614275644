import React from 'react';
import ReactDOM from 'react-dom/client';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import './App.css';

import App from './components/App.js';
import reportWebVitals from './reportWebVitals';

import store from './store/store'
import {Provider} from 'react-redux'
import {
  createBrowserRouter,
  RouterProvider,
  Route
} from "react-router-dom";
import MintPetalPage from './Pages/MintPetalPage'
import MintFlowerPage from './Pages/MintFlowerPage'
import Stake from './Pages/Staking'
import Stack from './Pages/Stack'

import ClaimRewards from './Pages/ClaimRewards'
import Home from './Pages/1home'
import Polyn from './Pages/ClaimRewardscopyPolyn'




const router = createBrowserRouter([
 
  {
    path: "mintpetal",
    element: <MintPetalPage/>,
  },
  {
    path: "mintflower",
    element: <MintFlowerPage/>,
  },
{
  path: "stake",
  element: <Stake/>,
},
{
  path: "stack",
  element: <Stack/>,
},
{
  path: "claim",
  element: <ClaimRewards/>,
},
{
  path: "/",
  element: <Home/>,
},
{
  path: "claim-polyn",
  element: <Polyn/>,
},

]);




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
//wrapped provider
<Provider store={store}>
  {/* <RouterProvider  router={router}/> */}
  <RouterProvider router={router} />
  </Provider>


);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
