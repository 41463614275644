import React from 'react'
import NavbarCopy from '../components/Component Copies Front Page/NavbarCopy'
import CenterCopy from '../components/Component Copies Front Page/CenterCopy'
import AppCopy from '../components/Component Copies Front Page/AppCopy'



function MintPetalPage() {return (



    <div>
        <AppCopy></AppCopy>
    </div>
)
}

export default MintPetalPage