import {useSelector,useDispatch} from 'react-redux'
import {useEffect,useRef,useState} from 'react'
import {loadBalances,loadDappBalance,loadPolynBalance} from '../../store/interactions'

import logo from'../../assets/lotus.svg'
import Blockies from 'react-blockies'
import {loadAccount} from '../../store/interactions'
import eth from '../../assets/eth.svg'
import config from '../../config.json'
import { Link } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav';
import navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// import '../../App.css'

const Navbar = () => {

    const provider = useSelector(state=>state.provider.chainId)
    const chainId = useSelector(state=>state.provider.chainId)
    const dappBalance = useSelector(state=>state.provider.Dappbalance)

    const account = useSelector(state=>state.provider.account)
    const petal = useSelector(state=>state.petal.balance)//loaded petal info
    const lotus = useSelector(state=>state.lotus.contract)
    const balance = useSelector(state=>state.provider.balance)
    const polynBalance = useSelector(state=>state.lotus.polynBalance)
    const reward = useSelector(state=>state.reward.contract)
    const dispatch = useDispatch()//impoted in

    const connectHandler=  async ()=>{//deals with button
        await loadAccount(provider,dispatch)//loading accounts LOADED IN
    }
    const networkHandler = async (e)=>{
        await window.ethereum.request({
        method:'wallet_switchEthereumChain',
        params:[{chainId:e.target.value}]
        })
    }

    useEffect(()=>{ //runs a function
        loadDappBalance(provider,dispatch)

          if(polynBalance&&account){
              loadBalances(petal,account,dispatch)//displays on page
          }
      })

      useEffect(()=>{ //runs a function
        loadPolynBalance(lotus,dispatch,account)
        console.log(reward)

          // if(petal&&account){
          //     loadBalances(polynBalance,dispatch,account,)//displays on page
          // }
      })

      


    return(
   <Container>
    <Row>

        <div className='exchange__header grid'>
        <div className='exchange__header--brand flex'>
            <Link to='/'><img src={logo}className="logo" alt="Lotus Logo"></img></Link>
            <Link to='/' style={{ textDecoration: 'none' }}><h1>Lotus</h1><br></br><h1>Farm</h1></Link>


        </div>

        <div className='exchange__header--networks flex'>
        <img src={eth} className="Eth Logo"></img>

        <Col>

        {chainId && (
            <select name="networks" id="networks" value= {config[chainId] ? `0x${chainId.toString(16)}` : `0`}   onChange={networkHandler}>
            <option value="0"disabled >
            Select Network
            </option>

            <option value="0x7A69" >
            LocalHost
            </option>
             <option value="0x89" >
            Polygon
            </option>
            <option value="0x13881" >
        Mumbai Test
            </option>

        </select>
        )}


</Col>

        </div>







        {/* <div className='routerSwitcherNFT'>
           <Link to="/"style={{ color: 'inherit', textDecoration: 'inherit'}}>NFT</Link>
        </div>

        <div className='routerSwitcherStake'>
        <Link to="stake"style={{ color: 'inherit', textDecoration: 'inherit'}}>STAKE</Link>
        </div> */}

        <div className='exchange__header--account flex'>

        {balance ?(
        <p><small>Wallet Balance</small>{Number(balance).toFixed(4)}</p>
        ) : (
        <p><small>Wallet Balance</small>0 ETH</p>
        )}





        {account ?(
        <a href={`http://goerli.etherscan.io/address/${account}`}>{account.slice(0,5)+"..."+account.slice(38,42)}
        <Blockies>
        seed={account}
        className="identicon"
        size={10}
        scale={3}
        </Blockies>
         </a>

        ) : (
        <button className="button"onClick={connectHandler}>Connect</button>
        )}





        </div>

      </div>


      </Row>
      <Row>
        <Col>
        <div className="NavMenu">
          <h1 className="NavItems"><Link to="/mintpetal"style={{ textDecoration: 'none' }}>Mint Petals</Link></h1>
          <h1 className="NavItems"><Link to="/stack"style={{ textDecoration: 'none' }}>Stack & Stake</Link></h1>     
          <h1 className="NavItems"><Link to="/mintflower"style={{ textDecoration: 'none' }}>Claim Flower</Link></h1>
          <h1 className="NavItems"><Link to="/claim"style={{ textDecoration: 'none' }}>Claim Rewards</Link></h1>

          </div>
        </Col>
      </Row>
      <hr style={{color:'white',width:'100%',height:'2px'}}></hr>

            <Row>
            <Col xs={2}><div className="containerEthinDapp">
        <h2 className="flash-button">Eth in lotus: {Number(dappBalance).toFixed(4)} </h2>
        </div></Col>
        <Col xs={2}><div className="containerEthinDapp">
        <h2 className="flash-button" style={{fontSize:"15px",padding:'9.5px'}} >Polyn Collected: {Number(polynBalance).toFixed(2)} </h2>
        {/* <h2 className="flash-button" style={{fontSize:"15px",padding:'9.5px'}} >Polyn Collected: {Number(loadPolynBalance).toFixed(4)} </h2> */}

        </div></Col>
       
            </Row>

      </Container>


    )
  }

  export default Navbar;