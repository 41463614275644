import {useEffect,useRef,useState} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import lotusimage from '../../assets/dapp.svg'
import lotuslogo from'../../assets/lotus.png'
import {loadBalances,transfer,mint,safemint,claimEth,loadDappBalance} from '../../store/interactions'
import lightnedpetals from '../../assets/lightenedpetals.mp4'
import {Link} from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion';

const Order = () => {

    const[mintamount,setMintAmount] =useState(0)
    const[mintamountFlower,setMintAmountFlower] =useState(0)
    const provider = useSelector(state=>state.provider.connection)
    const account =useSelector(state=>state.provider.account)

    const petal = useSelector(state=>state.petal.contract)


    const lotus = useSelector(state=>state.lotus.contract)
    const flower = useSelector(state=>state.flower.contract)
    const balance = useSelector(state=>state.petal.balances)
    const dappBalance = useSelector(state=>state.provider.Dappbalance)
    const dispatch = useDispatch()

    const depositRef = useRef(null)
    const withdrawRef = useRef(null)

    // const transferInProgress = useSelector(state=>state.lotus.transferInProgress)




    const amountHandler=(e,petal)=>{
        if (petal.address ==petal.address){

            setMintAmount(e.target.value)

            console.log({mintamount})
        }

    }

    const mintHandler=(e,petal)=>{
        e.preventDefault()
        if (petal.address ==petal.address){

            mint(provider,petal,mintamount,dispatch)
        }
        }

    const mintHandlerFlower=(e,flower)=>{
      e.preventDefault()
      if (flower.address == flower.address){
          safemint(provider,flower,dispatch)
      }
      }

      const claimHandler=(e,lotus)=>{
        e.preventDefault()
        if (lotus.address == lotus.address){

          claimEth(provider,petal,lotus,dispatch,account)
          }
        }





    useEffect(()=>{ //runs a function
      loadDappBalance(provider,dispatch)

        if(petal&&account){
            loadBalances(petal,account,dispatch)//displays on page
        }
    })
        // })[lotus,petal,account,transferInProgress])




    return (


      <div className="component exchange__orders">
      
        <div className = "petalimgContainer">
        <video src={lightnedpetals} className= "petalVideo" autoPlay muted loop >Your Browser Does not Support this Element</video>
        </div>

        <div className='component__header flex-between'>

        </div>

<div className='PetalFlex'>

        <form className='Petal Mint'onSubmit={(e)=>mintHandler(e,petal)} >
        {/* onSubmit={(e)=>mintHandler(e,petal)}place up */}
        
          <input type="text" id='amount' placeholder='0'className='petalinput' onChange={(e)=>amountHandler(e,petal)}/>


          <button className='petalMintButton' type='submit'>
          <span className='mint'>
            Mint Petal

        </span>
          </button>
          <div className='PetalMint'>
        <h1><strong>Mint Petals</strong></h1>
        <p>Input the amount of petals you wish to buy.</p>
        <p><strong>Price:</strong>  1 ETH</p>
          </div>
        </form>

        </div>
        
        <div>


          
        <Accordion >
      <Accordion.Item eventKey="0">

        <Accordion.Header>What is a Petal NFT?</Accordion.Header>
        <Accordion.Body>
        Petals represent how much ETH you own within our Lotus Farm ecosystem. For example, if you own 1 Petal, you own 1 ETH within our staking pools.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>How many Petals do I need in order to receive Ethereum staking rewards?</Accordion.Header>
        <Accordion.Body>
          8 Petals is the minimum amount of Petal NFTs you will need to start accruing staking rewards.  
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>How do I stake my Petals?</Accordion.Header>
        <Accordion.Body>
          In order to obtain staking rewards you must first claim a Lotus. 
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

    </div>







      </div>


    );
  }


  export default Order;