import {useEffect,useRef,useState} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import lotusimage from '../../assets/dapp.svg'
import lotuslogo from'../../assets/lotus.png'
import {loadBalances,transfer,mint,safemint,claimEth,loadDappBalance} from '../../store/interactions'
import lightnedpetals from '../../assets/lightenedpetals.mp4'
import {Link} from 'react-router-dom'
import balance from './BalanceCopyStake'
import Accordion from 'react-bootstrap/Accordion';

const Order = () => {

    const[mintamount,setMintAmount] =useState(0)
    const[mintamountFlower,setMintAmountFlower] =useState(0)
    const provider = useSelector(state=>state.provider.connection)
    const account =useSelector(state=>state.provider.account)

    const petal = useSelector(state=>state.petal.contract)


    const lotus = useSelector(state=>state.lotus.contract)
    const flower = useSelector(state=>state.flower.contract)
    const balance = useSelector(state=>state.petal.balances)
    const dappBalance = useSelector(state=>state.provider.Dappbalance)
    const dispatch = useDispatch()

    const depositRef = useRef(null)
    const withdrawRef = useRef(null)

    // const transferInProgress = useSelector(state=>state.lotus.transferInProgress)




    const amountHandler=(e,petal)=>{
        if (petal.address ==petal.address){

            setMintAmount(e.target.value)

            console.log({mintamount})
        }

    }

    const mintHandler=(e,petal)=>{
        e.preventDefault()
        if (petal.address ==petal.address){

            mint(provider,petal,mintamount,dispatch)
        }
        }

    const mintHandlerFlower=(e,flower)=>{
      e.preventDefault()
      if (flower.address == flower.address){
          safemint(provider,flower,dispatch)
      }
      }

      const claimHandler=(e,lotus)=>{
        e.preventDefault()
        if (lotus.address == lotus.address){

          claimEth(provider,petal,lotus,dispatch,account)
          }
        }





    useEffect(()=>{ //runs a function
      loadDappBalance(provider,dispatch)

        if(petal&&account){
            loadBalances(petal,account,dispatch)//displays on page
        }
    })
        // })[lotus,petal,account,transferInProgress])




    return (


      <div className="component exchange__orders">
        
        {/* <div className="NavMenu">
          <h1 className="NavItems"><Link to="/"style={{ textDecoration: 'none' }}>Mint Petals</Link></h1>
          <h1 className="NavItems"><Link to="/mintflower"style={{ textDecoration: 'none' }}>Claim Flower</Link></h1>
          <h1 className="NavItems"><Link to="/stake"style={{ textDecoration: 'none' }}></Link>Stake</h1>
          <h1 className="NavItems"><Link to="/claim"style={{ textDecoration: 'none' }}>Claim Rewards</Link></h1>

          </div> */}

        <br></br>

        
        {/* <img src={lotuslogo} alt=''className='petalimg'></img> */}


        {/* <div className = "petalimgContainer">
        <video src={lightnedpetals} className= "petalVideo" autoplay muted controls loop >Your Browser Does not Support this Element</video>
        </div>

        <div className='component__header flex-between'>

        </div> */}



<div className='PetalFlex'>

        {/* <form className='Petal Mint'onSubmit={(e)=>mintHandler(e,petal)} >
        <h1>Mint Petals</h1>
        <h5>Input amount of petals you wish to buy.</h5>
        <h5>Price:  1 ETH each.</h5>
          <input type="text" id='amount' placeholder='0'className='petalinput' onChange={(e)=>amountHandler(e,petal)}/>


          <button className='petalbutton petalbutton--filled' type='submit'>
          <span className='mint'>
            Mint Petal

        </span>
          </button>
        </form> */}




{/* 
        <form className='Petal Mint'onSubmit={(e)=>mintHandlerFlower(e,flower)} >
            <h1>Mint Flower</h1>
            <input type="text" id='amount' className='petalinput'style={{ background: 222326}}/>


          <button className='petalbutton petalbutton--filled' type='submit'>
        <span className="mint">
            Mint Flower
        </span>
          </button>
        </form> */}




{/* 
        <form className='Petal Mint'onSubmit={(e)=>claimHandler(e,lotus)}>
            <h1>Claim Eth </h1>
          <input type="text" id='amount'className='petalinput' style={{ background: 222326}}/>


          <button className='petalbutton petalbutton--filled'  type='submit'>
        <span className="mint">
            Claim Ether
        </span>
          </button>
        </form> */}
        </div>
        
      </div>


    );
  }


  export default Order;