import React from 'react'
import NavbarCopy from '../components/Component Copies Front Page/NavbarCopy'
import CenterCopy from '../components/Component Copies Front Page/CenterCopy'
import AppCopyFlower from '../components/Component Copies Front Page/AppCopyFlower'
import AppCopyClaim from '../components/Component Copies Front Page/AppCopyClaim'


function MintPetalPage() {return (



    <div>
        <AppCopyClaim></AppCopyClaim>
    </div>
)
}

export default MintPetalPage