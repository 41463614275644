import {useEffect,useRef,useState} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import lotusimage from '../../assets/dapp.svg'
import {Button, Alert,Container,Row,Col} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import Nav from 'react-bootstrap/Nav';
import '../../../src/App.css'
import Accordion from 'react-bootstrap/Accordion';
import {Link} from 'react-router-dom'
import {loadBalances,transfer,transferFlower,transferToLotus,loadLotusBalances,loadPolynBalance,transferFromLotus,transferFromFlower} from '../../store/interactions'

const Balance = () => {
    const [isDepositPetal,setIsDepositPetal] =useState(true)
    const [isDeposit,setIsDeposit] =useState(true)
    const[petalTransferAmount,setPetalTransferAmount] =useState(0)
    const[flowerTransferAmount,setFlowerTransferAmount] =useState(0)
    const[PolynTokenID,setPolynTokenID] = useState(0)
    const provider = useSelector(state=>state.provider.connection)
    const account =useSelector(state=>state.provider.account)
    const petal = useSelector(state=>state.petal.contract)
    const balance = useSelector(state=>state.petal.balance)
    const flower = useSelector(state=>state.flower.contract)
    const flowerbalance = useSelector(state=>state.flower.balance)
    const flowersOwned = useSelector(state=>state.flower.amountOwned)
    const lotus = useSelector(state=>state.lotus.contract)
    const reward = useSelector (state=>state.reward.contract)
    // const balances = useSelector(state=>state)
    // const ownerInfo = useSelector(state=>state.lotus.ownerInfo)
    const dispatch = useDispatch()

    const depositRef = useRef(null)
    const depositRefPetal = useRef(null)

    const withdrawRefPetal = useRef(null)
    const withdrawRef = useRef(null)


    // const transferInProgress = useSelector(state=>state.lotus.transferInProgress)
    const choosePetal = useRef(null)
    const chooseFlower = useRef(null)

    const tabHandler = (e) =>{
        if(e.target.className !==depositRef.current.className){
           e.target.className='tab tab--active'
            depositRef.current.className='tab'
            setIsDeposit(false)
        } else{
            e.target.className='tab tab--active'
            withdrawRef.current.className='tab'
            setIsDeposit(true)
        }
    }

    const tabHandlerPetal = (e) =>{
      if(e.target.className !==depositRefPetal.current.className){
         e.target.className='tabPetal tab--activePetal'
          depositRefPetal.current.className='tabPetal'
          setIsDepositPetal(false)
      } else{
          e.target.className='tab tab--active'
          withdrawRefPetal.current.className='tabPetal'
          setIsDepositPetal(true)
      }
  }

    const amountHandler=(e,petal)=>{
        if (petal.address ==petal.address){
            setPetalTransferAmount(e.target.value)
        }
        console.log({petalTransferAmount})
    }


    const amountHandlerFlower=(e,petal)=>{
      if (flower.address ==flower.address){
          setFlowerTransferAmount(e.target.value)
      }
      console.log({flowerTransferAmount})
  }
  const chooseHandler = (e) =>{
    if(e.target.className !==choosePetal.current.className){
       e.target.className='tabPetal tab--activePetal'
        choosePetal.current.className='tabPetal'

    } else{
        e.target.className='tab tab--active'
        withdrawRefPetal.current.className='tabPetal'
    }
}
  const amountHandlerPolyn=(e)=>{

      setPolynTokenID(e.target.value)

    console.log({PolynTokenID})
}



    const depositHandler=(e,petal)=>{
        e.preventDefault()
        if (petal.address ==petal.address){
            transferFlower(provider,flower,'Deposit',petal,petalTransferAmount,dispatch)
        setPetalTransferAmount(0)
        }

    }
      // (provider,lotus,transferType,flower,tokenId,dispatch)
    const depositHandlerforFlower=(e,flower)=>{
      e.preventDefault()
      if (lotus.address ==lotus.address){
        transferToLotus(provider, lotus,'Deposit',flower,flowerTransferAmount,dispatch)
      setFlowerTransferAmount(0)
      }
  }
  const depositHandlerPolyn=(e,reward)=>{
    e.preventDefault()
    if (reward.address ==reward.address){
      loadPolynBalance(reward,dispatch,PolynTokenID)
      setPolynTokenID(0)
    }
}

    const withdrawHandler=(e,petal)=>{
        e.preventDefault()
    if (lotus.address ==lotus.address){//changed from flower
        transferFromLotus(provider,lotus,'Withdraw',petal,flowerTransferAmount,dispatch)
        setPetalTransferAmount(0)
    }else{

    }
    }

    const withdrawHandlerPetal=(e,flower)=>{
      e.preventDefault()
  if (flower.address ==flower.address){
      transferFromFlower(provider,'Withdraw',flower, petalTransferAmount, dispatch)
      setPetalTransferAmount(0)
  }else{

  }
  }


    useEffect(()=>{ //runs a function
        if(petal&&account){
            loadBalances(petal,flower,account,dispatch)//displays on page
        }
    })

    useEffect(()=>{ //runs a function
      if(lotus&&flower&&account){
        loadLotusBalances(flower,account,dispatch)//displays on page
      }
  })

        // })[lotus,petal,account,transferInProgress])
    return (
      <Container className="stakingbox">

<Row >
          <Col >
         <div className= "switcher">
            <button className='tab  tab--active2'><Link to="/stack"style={{ textDecoration: 'none' }}>Stack Petals</Link> </button>
            <button  className='tab'><Link to="/stake"style={{ textDecoration: 'none' }}>Stake Lotus</Link> </button>

          </div>
          </Col>
        </Row>



        <Row>
        <div className="staking-Background">
          <Col lg={12}>
      {/* <span className='stakingPetalFlower'> */}

      {/* <div className='component exchange__transfers'> */}
        {/* <div className='component__header flex-between'> */}
          <h2>Stack Petals</h2>
          <div>
            <button onClick={tabHandlerPetal} ref = {depositRefPetal} className='tabPetal tab--activePetal'>Deposit </button>
            <button onClick={tabHandlerPetal} ref = {withdrawRefPetal} className='tabPetal'>Withdraw </button>
          </div>
        {/* </div> */}

        {/* Deposit/Withdraw Component 1 (DApp) */}

        {/* <div className='exchange__transfers--form'> */}
          {/* <div className='flex-between'> */}
        <p><small>Petals in Account</small><br/>{balance && balance}</p>
        {/* <p><small>Petals in Flower</small><br/>{flowerbalance && flowerbalance}</p> */}


<form onSubmit={isDepositPetal?(e)=>depositHandler(e,petal) : (e)=>withdrawHandlerPetal(e,flower)}>
            <input
            type="text"
            id='token0'
        value={petalTransferAmount==0?'':petalTransferAmount}
             placeholder='Amount of Petals To Stack'
              onChange={(e)=> amountHandler(e,petal)}/>

            <button className='petalMintButton' type='submit'>
             {isDepositPetal?(
                 <span>Deposit Petals</span>
             ):(
                <span>Withdraw Petals</span>
             )}
            </button>

</form>


                <div className='PetalMint'>
              <h1 style={{textShadow:'none'}}><strong>How to stack your Petals</strong></h1>
              <ul style={{textShadow:'none'}}>
                <li>Input the amount of petals you wish to stack. </li>
                <li>Stacking Petals is free!</li>
                <li>You will be eligible to claim a Lotus NFT when you stack 8, 16, 24, or 32 Petals!</li>
              </ul>

                </div>
</Col>

</div>
      </Row>
      </Container>






    );
  }

  export default Balance;