import {ethers} from 'ethers'
import { Transaction } from 'ethers'
import PETALABI from '../abis/Petal.json'
import FLOWERABI from '../abis/Flower.json'
import LOTUSABI from'../abis/Lotus.json'
import REWARDABI from '../abis/Reward.json'
import { dispatch } from 'react'
import Alert from 'react-bootstrap'


//interactions are exported over to app.js to reduce clutter
export const loadProvider = (dispatch)=> {
    const connection = new ethers.providers.Web3Provider(window.ethereum)
    dispatch({type: 'PROVIDER_LOADED',connection:connection})

    return connection
}





export const loadNetwork = async (provider, dispatch) => {
    const { chainId } = await provider.getNetwork()
    dispatch({ type: 'NETWORK_LOADED', chainId })

    return chainId
  }


export const loadAccount = async (provider,dispatch)=> {
    const accounts = await window.ethereum.request({method:'eth_requestAccounts'})
    const account = ethers.utils.getAddress(accounts[0])
    dispatch({type:'ACCOUNT_LOADED',account})

    let balance = await provider.getBalance(account)
    balance = ethers.utils.formatEther(balance)
    dispatch({type:'ETHER_BALANCE_LOADED',balance})

    return account

}


export const loadPetal = async (provider,address,dispatch,account)=> {//reduxII video uses multiple tokens
    let petal
    petal = new ethers.Contract(address, PETALABI,provider)
    // test2 =await petal.balanceOf("0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266")
    // balance = test2.toString()

    dispatch({type:'PETAL_LOADED',petal})



    return petal
}

export const loadLotus = async (provider,address,dispatch)=> {//reduxII video uses multiple tokens
    let lotus

    lotus = new ethers.Contract(address, LOTUSABI,provider)

    dispatch({type:'LOTUS_LOADED',lotus})

    return lotus

}


export const loadReward = async (provider,address,dispatch)=> {//reduxII video uses multiple tokens
    let reward

    reward = new ethers.Contract(address, REWARDABI, provider)

    dispatch({type:'REWARD_LOADED',reward})

    return reward

}


// --------------------------UNCOMMENT

export const loadFlower = async (provider,address,dispatch)=> {//reduxII video uses multiple tokens

    let flower, name
    flower = new ethers.Contract(address, FLOWERABI,provider)
    name =await flower.name()
    dispatch({type:'FLOWER_LOADED',flower,name})



    return flower


}




// export const loadReward = async (provider,address,dispatch)=> {//reduxII video uses multiple tokens
//     let reward, balance
//     reward = new ethers.Contract(address, REWARDABI,provider)
//     balance =await reward.balance()
//     dispatch({type:'REWARD_BALANCE_LOADED',reward,balance})

//     return reward
// }


// -------------------------UNCOMMENT ABOVE








//-------------LOAD BALANCES------------------//

// export const loadBalaxnces = async (lotus,petal,account,dispatch) =>{
//     let balanceOf, balanceOfLotus


//     balanceOf=ethers.utils.formatUnits(await petal.balanceOf(account,0),0)
//     dispatch({type:'PETAL_BALANCEOF_LOADED',petal,balanceOf})


    // balance = ethers.utils.formatUnits( await lotus.balanceOf(account), 18) //lotus
    // dispatch({type:'LOTUS_PETAL_BALANCEOF_LOADED',balance})

    // balance = await petal2.balanceOf(account) //lotus
    // dispatch({type:'LOTUS_PETAL2_BALANCEOF_LOADED',balance})

    // balance = ethers.utils.formatUnits( await flower.balanceOf(account), 18) //lotus
    // dispatch({type:'FLOWER_BALANCEOF_LOADED',balance})


// -------------------------------------UNCOMMENT BELOW HERE----------------------------------------

export const loadBalances = async (petal,flower, account, dispatch) => {
    let   balance=ethers.utils.formatUnits((await petal.balanceOf(account,0)),0)
    dispatch({ type: 'PETAL_BALANCEOF_LOADED', balance })


    // let balanceFlower=ethers.utils.formatUnits(await flower.viewPetalsInFlower("0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512"),0)
    // dispatch({type:'PETALS_IN_FLOWER_LOADED',balanceFlower})

  }

  export const loadLotusBalances = async (flower, account, dispatch) => {

    let balanceFlower=ethers.utils.formatUnits(await flower.balanceOf(account),0)
    dispatch({type:'FLOWERS_OWNED_LOADED',balanceFlower})

  }

  export const loadDappBalance = async (provider, dispatch) => {

    let contract =  await provider.getBalance("0xdb7162a6916162baF8491f48F764Fcd23fb3197B")
    let contractBalance = ethers.utils.formatEther(contract)
    // let balance = ethers.utils.formatEther(contractBalance)
    dispatch({type:'CONTRACT_BALANCE_LOADED',contractBalance})
  }


  export const loadPolynBalance = async (lotus, dispatch,address) => {

    let   polynBalance=await lotus.viewUserPolynRewards(address,0) 
    polynBalance=ethers.utils.formatEther(polynBalance)
    dispatch({ type: 'POLYN_BALANCE_LOADED', polynBalance })
  }









export const transfer = async (provider,lotus,transferType,petal,amount,dispatch) => {
    let transaction
    dispatch({type:'TRANSFER_REQUEST'})

    try {
        const signer = await provider.getSigner()
        const amountToTransfer = ethers.utils.parseUnits(amount.toString(),18)

        if(transferType=='Deposit'){
            transaction = await petal.connect(signer).setApprovalForAll(lotus.address,true)//approval
            await transaction.wait()

            transaction = await lotus.connect(signer).depositPetals(0,amount,"0x00")
        } else{

        transaction = await lotus.connect(signer).withdrawPetals(0,amount,"0x00")

        }



    }catch(error){
        dispatch({type:'TRANSFER_FAIL'})
    }

    await transaction.wait()

}

export const transferFlower = async (provider,flower,transferType,petal,tokenId,dispatch) => {
    let transaction
    dispatch({type:'TRANSFER_REQUEST'})

    try {
        const signer = await provider.getSigner()
        // const amountToTransfer = ethers.utils.parseUnits(tokenId)

        if(transferType=='Deposit'){
            transaction = await petal.connect(signer).setApprovalForAll(flower.address,true)//approval // change the second param to token Id
            await transaction.wait()

            transaction = await flower.connect(signer).depositPetals(0,tokenId)
        } else{

        transaction = await flower.connect(signer).withdrawPetals(0)

        }



    }catch(error){
        dispatch({type:'TRANSFER_FAIL'})
    }

    await transaction.wait()
}

export const transferFromFlower = async (provider,transferType,flower, amount,dispatch) => { //make new tokenId call new collection
    let transaction
    dispatch({type:'TRANSFER_REQUEST'})


        const signer = await provider.getSigner()
        transaction = await flower.connect(signer).withdrawPetals(0,amount)
        await transaction.wait()

}




export const transferToLotus = async (provider,lotus,transferType,flower,tokenId,dispatch) => {
    let transaction
    dispatch({type:'TRANSFER_REQUEST'})

    try {
        const signer = await provider.getSigner()
        // const amountToTransfer = ethers.utils.parseUnits(tokenId)

        if(transferType=='Deposit'){
            transaction = await flower.connect(signer).approve(lotus.address,tokenId)//approval // change the second param to token Id
            await transaction.wait()

            transaction = await lotus.connect(signer).depositFlower(tokenId)
        } else{

        transaction = await flower.connect(signer).withdrawPetals(0)

        }
    }catch(error){
        dispatch({type:'TRANSFER_FAIL_FLOWER'})
    }

    await transaction.wait()

}

export const transferFromLotus = async (provider,lotus,transferType,flower,tokenId,dispatch) => {
    let transaction
    dispatch({type:'TRANSFER_REQUEST'})


        const signer = await provider.getSigner()
        transaction = await lotus.connect(signer).withdrawFlower(tokenId)
        await transaction.wait()

}




export const mint = async (provider,petal,amount,dispatch) => {
    let transaction

    dispatch({type:'MINT_REQUEST'})

    const signer = await provider.getSigner()

    transaction = await petal.connect(signer).mint(0,amount,"0x00",{value: ethers.utils.parseEther(amount)})


    await transaction.wait()

}


export const claimEth = async (provider,petal,lotus,dispatch,account) => {
    let transaction

    dispatch({type:'CLAIM_REQUEST'})

    const signer = await provider.getSigner()

    transaction = await lotus.connect(signer).claimETHRewards(0)


    await transaction.wait()
}

export const claimPolyn = async (provider,petal,lotus,dispatch,account) => {
    let transaction

    dispatch({type:'CLAIM_POLYN_REQUEST'})

    const signer = await provider.getSigner()

    transaction = await lotus.connect(signer).claimPolyn(0)


    await transaction.wait()
}

export const petalBalance = async (provider,petal,dispatch,account) => {
    let transaction

    dispatch({type:'PETAL_BALANCE'})

    const signer = await provider.getSigner()

    transaction = await petal.connect(signer).balanceOf(account,0)


    await transaction.wait()
}





export const safemint = async(provider,flower,dispatch) => {
    let transaction

    dispatch({type:'MINT_FLOWER'})

    const signer = await provider.getSigner()

    transaction = await flower.connect(signer).safeMint({value: ethers.utils.parseEther("0.1")})


    await transaction.wait()

}

// -------------------------------------UNCOMMENT ABOVE HERE----------------------------------------



















// //WORKING
// export const loadPetalx = async (provider,address,dispatch)=> {//reduxII video uses multiple tokens
//     let petal,balanceOf
//     petal = new ethers.Contract(address, PETALABI,provider)
//     balanceOf=await petal.balanceOf("0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266",0)
//     dispatch({type:'PETAL_BALANxCE_LOADED',petal,balanceOf})




//     return petal
// }