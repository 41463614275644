import {useEffect,useRef,useState} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import lotusimage from '../../assets/dapp.svg'
import lotuslogo from'../../assets/lotus.png'
import {loadBalances,transfer,mint,safemint,claimEth,loadDappBalance} from '../../store/interactions'
import lightnedpetals from '../../assets/lightenedpetals.mp4'
import {Link} from 'react-router-dom'
import wildflower from '../../assets/wildflower.mp4'
import awakened from '../../assets/awakened.mp4'
import legendary from '../../assets/legendary.mp4'
import sacred from '../../assets/SacredLotus.mp4'
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import {Carousel} from 'react-bootstrap'

const Order = () => {

    const[mintamount,setMintAmount] =useState(0)
    const[mintamountFlower,setMintAmountFlower] =useState(0)
    const provider = useSelector(state=>state.provider.connection)
    const account =useSelector(state=>state.provider.account)

    const petal = useSelector(state=>state.petal.contract)


    const lotus = useSelector(state=>state.lotus.contract)
    const flower = useSelector(state=>state.flower.contract)
    const balance = useSelector(state=>state.petal.balances)
    const dappBalance = useSelector(state=>state.provider.Dappbalance)
    const dispatch = useDispatch()

    const depositRef = useRef(null)
    const withdrawRef = useRef(null)

    // const transferInProgress = useSelector(state=>state.lotus.transferInProgress)




    const amountHandler=(e,petal)=>{
        if (petal.address ==petal.address){

            setMintAmount(e.target.value)

            console.log({mintamount})
        }

    }

    const mintHandler=(e,petal)=>{
        e.preventDefault()
        if (petal.address ==petal.address){

            mint(provider,petal,mintamount,dispatch)
        }
        }

    const mintHandlerFlower=(e,flower)=>{
      e.preventDefault()
      if (flower.address == flower.address){
          safemint(provider,flower,dispatch)
      }
      }

      const claimHandler=(e,lotus)=>{
        e.preventDefault()
        if (lotus.address == lotus.address){

          claimEth(provider,petal,lotus,dispatch,account)
          }
        }





    useEffect(()=>{ //runs a function
      loadDappBalance(provider,dispatch)

        if(petal&&account){
            loadBalances(petal,account,dispatch)//displays on page
        }
    })
        // })[lotus,petal,account,transferInProgress])




    return (
<div>

    <div className="component exchange__orders">


<div className = 'carousel'>
  <Container>
    <Row>
    <strong style={{textAlign:'center'}}>Stack Petals and watch your Lotus automatically Transform!</strong>
    </Row>
    <Row>
      <Carousel>
  <Carousel.Item>
  <video src={wildflower}  className= "petalVideo" autoPlay muted  loop  >Your Browser Does not Support this Element</video>
  <Carousel.Caption>
  <p><strong>Wild Lotus:</strong> 8 petals</p>
  <p><strong>Ownership Rewards:</strong> 25%</p>
  </Carousel.Caption>
  </Carousel.Item>

  <Carousel.Item>
  <video src={awakened}  className= "petalVideo" autoPlay muted  loop  >Your Browser Does not Support this Element</video>
  <Carousel.Caption>
  <p><strong>Mystic Lotus:</strong> 16 petals</p>
  <p><strong>Ownership Rewards:</strong> 50%</p>
  </Carousel.Caption>
  </Carousel.Item>

  <Carousel.Item>
  <video src={legendary}  className= "petalVideo" autoPlay muted  loop  >Your Browser Does not Support this Element</video>
  <Carousel.Caption>
  <p><strong>Ethereal Lotus:</strong> 24 petals</p>
  <p><strong>Ownership Rewards:</strong> 75%</p>
  </Carousel.Caption>
  </Carousel.Item>

  <Carousel.Item>
  <video src={sacred}  className= "petalVideo" autoPlay muted  loop  >Your Browser Does not Support this Element</video>
  <Carousel.Caption>
  <p><strong>Sacred Lotus:</strong> 32 petals</p>
  <p><strong>Ownership Rewards:</strong> 100%</p>
  </Carousel.Caption>
  </Carousel.Item>
      </Carousel>
  </Row>

  </Container>
  </div>
<Container>
  <Row  >
    <Col className='justify-content-md-center' >
   
    </Col>
</Row>

</Container>
  
<div className='PetalFlex'>


        <form onSubmit={(e)=>mintHandlerFlower(e,flower)} >


<button className='petalMintButton' type='submit'>
<span className="mint">
  Mint Flower
</span>
</button>
          <div className='PetalMint'>
            <div >
            <h1><strong>How to Claim Your Flower (limited to 4 per node)</strong></h1>
            <br></br>
            <ul>
              <li><strong>Stake</strong> at least 8 petals.</li>
              <li>Pay the Lotus cost and gas fee. Each Lotus costs .1 ETH.</li>
              <li>Lotus NFTs generate ETH and Polyn rewards.</li>
              <li>Lotus NFTs achieve higher levels of conciousness,</li>
              <p>generate greater rewards, and automatically change!</p>

            </ul>
           </div>
            </div>

        </form>


        </div>



        <div>


        <Accordion >
      <Accordion.Item eventKey="0">
        <Accordion.Header>What is a Lotus NFT?</Accordion.Header>
        <Accordion.Body>
          Lotus NFTs allow you to claim ETH and Polyn rewards. Lotus NFTs are also used to differentiate members within our community according to the amount of ETH they have staked with us.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>What are the ranks and yield amounts?</Accordion.Header>
        <Accordion.Body>
          8, 16, 24, 32
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Do I need a Lotus NFT in order to claim ETH and Polyn rewards?</Accordion.Header>
        <Accordion.Body>
          Yes.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div>
      </div>

      </div>
    );
  }


  export default Order;