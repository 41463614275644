import {useEffect,useRef,useState} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import lotusimage from '../../assets/dapp.svg'
import lotuslogo from'../../assets/lotus.png'
import {loadBalances,transfer,mint,safemint,claimEth,loadDappBalance,claimPolyn} from '../../store/interactions'
import lightnedpetals from '../../assets/lightenedpetals.mp4'
import {Link} from 'react-router-dom'
import wildflower from '../../assets/wildflower.mp4'
import {Button, Alert,Container,Row,Col} from 'react-bootstrap'
import eth from '../../assets/ethereum.png'

const Order = () => {

    const[mintamount,setMintAmount] =useState(0)
    const[mintamountFlower,setMintAmountFlower] =useState(0)
    const provider = useSelector(state=>state.provider.connection)
    const account =useSelector(state=>state.provider.account)

    const petal = useSelector(state=>state.petal.contract)


    const lotus = useSelector(state=>state.lotus.contract)
    const flower = useSelector(state=>state.flower.contract)
    const balance = useSelector(state=>state.petal.balances)
    const dappBalance = useSelector(state=>state.provider.Dappbalance)
    const dispatch = useDispatch()

    const depositRef = useRef(null)
    const withdrawRef = useRef(null)

    // const transferInProgress = useSelector(state=>state.lotus.transferInProgress)




    const amountHandler=(e,petal)=>{
        if (petal.address ==petal.address){

            setMintAmount(e.target.value)

            console.log({mintamount})
        }

    }

    const mintHandler=(e,petal)=>{
        e.preventDefault()
        if (petal.address ==petal.address){

            mint(provider,petal,mintamount,dispatch)
        }
        }

    const mintHandlerFlower=(e,flower)=>{
      e.preventDefault()
      if (flower.address == flower.address){
          safemint(provider,flower,dispatch)
      }
      }

      const claimHandler=(e,lotus)=>{
        e.preventDefault()
        if (lotus.address == lotus.address){

          claimEth(provider,petal,lotus,dispatch,account)
          }
        }

        const claimHandler2=(e,lotus)=>{
          e.preventDefault()
          if (lotus.address == lotus.address){

            claimPolyn(provider,petal,lotus,dispatch,account)
            }
          }





    useEffect(()=>{ //runs a function
      loadDappBalance(provider,dispatch)

        if(petal&&account){
            loadBalances(petal,account,dispatch)//displays on page
        }
    })
        // })[lotus,petal,account,transferInProgress])




    return (




<Container>
  
<Row className="justify-content-md-center">
<Col lg={3} className = "claimSwitch">
<button  className='tab tab--active2'><Link to="/claim"style={{ textDecoration: 'none' }}>Claim Ethereum</Link> </button>
<button className='tab'><Link to="/claim-polyn"style={{ textDecoration: 'none' }}>Claim Polyn</Link> </button>
</Col>


</Row>

  <Row className="justify-content-md-center">


    <div className = 'claimBackground'>
      <img src={eth} alt='eth logo' style={{height:'8em', width:'8em', alignItems:'center'}} className="ethLogoClaim"></img>
    <Col className = 'claimPage' >
        <div >

          <div className='claimEther'>

                    <button className='claimEtherB' onClick={(e)=>claimHandler(e,lotus)}>
                  <span className="mint">
                    Claim Ether
                  </span>
                    </button>
            </div>

              <div className='claimInfo'>
                  <h1><strong>Claim Ether</strong></h1>
                  <p>If you have a Flower NFT staked you may claim this season's rewards.</p>
                  <p><strong>Ether rewards can only be claimed once per season!</strong> </p>
                    </div>


       </div>
   </Col>
</div>
        </Row>
        </Container>




    );
  }


  export default Order;