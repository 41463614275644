import React from 'react'
import NavbarCopy from '../components/Component Copies Front Page/NavbarCopy'
import CenterCopy from '../components/Component Copies Front Page/CenterCopy'
import AppCopyStack from '../components/Component Copies Front Page/AppCopyStack'



function MintPetalPage() {return (



   
        <AppCopyStack></AppCopyStack>
 
)
}

export default MintPetalPage