import {createStore, combineReducers,applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import{composeWithDevTools} from 'redux-devtools-extension'

import {provider,petal,flower,lotus,reward} from './reducers'


const reducer =combineReducers({
    provider,
    petal,
    flower,
    lotus,
    reward



})

const initialState={}

const middleware =[thunk]

const store=createStore(reducer,initialState,composeWithDevTools(applyMiddleware(...middleware)))

export default store