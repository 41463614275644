export const provider = (state ={} , action)=>{

    switch(action.type){

        case'PROVIDER_LOADED':
        return{
            ...state,
            connection:action.connection
          }
        //     case'NETWORK_LOADED':
        // return{
        //     ...state,
        //     network:action.network
        case'NETWORK_LOADED':
        return{
            ...state,
            chainId:action.chainId
          }
          case'ACCOUNT_LOADED':
          return{
              ...state,
              account:action.account
            }
          case'ETHER_BALANCE_LOADED':
        return{
            ...state,
            balance:action.balance
          }

          case'CONTRACT_BALANCE_LOADED':
        return{
            ...state,
            Dappbalance:action.contractBalance
          }




    default:
        return state
    }
}

//make multiple
export const petal = (state ={loaded: false,contract:null} , action)=>{

  switch(action.type){

      case'PETAL_LOADED':
      return{
          ...state,
          loaded:true,
          contract:action.petal,
          balances:action.balance,
        }
      case 'MINT_REQUEST':
      return {
        ...state,
        transaction: {
          transactionType:'Transfer',
          isPending:true,
          isSuccessful:false
        },
        transferInProgress:true
      }

      case 'PETAL_BALANCEOF_LOADED':
        return {
          ...state,
            balance:action.balance
          }

          default:
            return state
  }

}



export const lotus = (state ={loaded: false,contract:null} , action)=>{

  switch(action.type){

      case'LOTUS_LOADED':
      return{
          ...state,
          loaded:true,
          contract:action.lotus,

        }

      case 'CONTRACT_BALANCE_LOADED':
        return {
          ...state,
            balance:action.contractBalance
          }



        case 'CLAIM_REQUEST':
    return {
      ...state,
      transaction: {
        transactionType:'Transfer',
        isPending:true,
        isSuccessful:false
      },
      transferInProgress:true
    }

    case 'CLAIM_POLYN_REQUEST':
      return {
        ...state,
        transaction: {
          transactionType:'Transfer',
          isPending:true,
          isSuccessful:false
        },
        transferInProgress:true
      }

      case 'POLYN_BALANCE_LOADED':
        return {
            ...state,
              polynBalance:action.polynBalance
            }

    default:
      return state
  }

}





export const flower = (state ={loaded: false,contract:null} , action)=>{

  switch(action.type){

      case'FLOWER_LOADED':
      return{
          ...state,
          loaded:true,
          contract:action.flower,
        }

        case 'MINT_FLOWER':
          return {
            ...state,
            transaction: {
              transactionType:'Transfer',
              isPending:true,
              isSuccessful:false
            },
            transferInProgress:true
          }

          case 'PETALS_IN_FLOWER_LOADED':
        return {
          ...state,
            balance:action.balanceFlower
          }

          case'FLOWERS_OWNED_LOADED':
          return{
              ...state,
              loaded:true,
              amountOwned:action.balanceFlower,

                  }


  default:
      return state
  }


}


export const reward = (state ={loaded: false,contract:null} , action)=>{
  
  switch(action.type){

   

      case 'REWARD_LOADED':
        return {
          ...state,
          loaded:true,
            contract:action.reward
          }


          
            
              default:
                return state
}

}


















