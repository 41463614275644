import React from 'react'
import NavbarCopy from '../components/Component Copies Front Page/NavbarCopy'
import CenterCopy from '../components/Component Copies Front Page/CenterCopy'
import AppCopyFlower from '../components/Component Copies Front Page/AppCopyFlower'
import AppCopyClaimPolyn from '../components/Component Copies Front Page/AppCopyClaimPolyn'


function MintPetalPage() {return (



    <div>
        <AppCopyClaimPolyn></AppCopyClaimPolyn>
    </div>
)
}

export default MintPetalPage