import React, { useState,useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import '../App.css';
import emailjs from '@emailjs/browser'








const EmailCaptureForm = () => {


  const form = useRef();


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ogjgygh', 'template_d6t7egm', form.current, 'Z4tV3jN-HOrbJEHlY')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };


  return (

    <div className="emailForm">
        <div className="captureheading">
           <h1 style={{color: "black"}}>Coming Soon!</h1>
    <h3 style={{color: "black"}}>Enter your email and be among the first to mint!</h3>
        </div>



<Form ref={form} onSubmit={sendEmail}>


      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label >Email address</Form.Label>
        <Form.Control type="email" placeholder="Enter email" className="form-control1" name="user_email"/>
        <Form.Text className="text-muted">
          We'll never share your email with anyone else and we will never spam you.
        </Form.Text>
      </Form.Group>

      <Button variant="primary" type="submit" className="flash-buttonHome">
        Submit
      </Button>
    </Form>



</div>

  );
};

export default EmailCaptureForm;
