import React from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import { Link } from 'react-router-dom'
import logo from '../assets/lotus.svg'

import  '../assets/home1.scss'
// import  '../assets/animated.css'
import  '../../node_modules/font-awesome/css/font-awesome.min.css';
import  '../../node_modules/elegant-icons/style.css';
import  '../../node_modules/et-line/style.css';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import  '../../node_modules/bootstrap/dist/js/bootstrap.js';

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const inline = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  .d-inline{
    display: inline-block;
   }
`;

const slidermainparticle= () => (
 <div className="container">
    <div className="row align-items-center">
          <div className="col-md-6">
              <div className="spacer-single"></div>
              <h6><span><img src={logo} style={{width:'200px'}} alt='logo'></img></span>
               {/* <span className="text-uppercase color">Lotus Farm</span>  */}
                </h6>
              <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={900} triggerOnce>
              <h1 className="col-white">Claim Staked Ethereum with NFTs</h1>
              </Reveal>
              <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={900} triggerOnce>
              <p className="lead col-white">
             Fractionalized pools featuring 1:1 staking with Ethereum nodes
              </p>
              </Reveal>
              <div className="spacer-10"></div>
              <Reveal className='onStep d-inline' keyframes={inline} delay={800} duration={900} triggerOnce>
             {/*  <span onClick={()=> window.open("#", "_self")} className="btn-main inline lead"><Link to="/mintpetal"style={{ textDecoration: 'none',color:'white' }}>Explore the App</Link></span> */} 
              <div className="mb-sm-30"></div>
              
</Reveal>

              <Reveal className='onStep d-inline' keyframes={inline} delay={900} duration={1200} triggerOnce>
              <div className="row">
                  <div className="spacer-single"></div>
                  <div className="row">
                          {/* <div className="col-lg-4 col-md-6 col-sm-4 mb30">
                              <div className="de_count text-left">
                                  <h3><span>94215</span></h3>
                                  <h5 className="id-color">Collectibles</h5>
                              </div>
                          </div> */}

                          {/* <div className="col-lg-4 col-md-6 col-sm-4 mb30">
                              <div className="de_count text-left">
                                  <h3><span>27</span>k</h3>
                                  <h5 className="id-color">Auctions</h5>
                              </div>
                          </div> */}

                          {/* <div className="col-lg-4 col-md-6 col-sm-4 mb30">
                              <div className="de_count text-left">
                                  <h3><span>4</span>k</h3>
                                  <h5 className="id-color">NFT Artist</h5>
                              </div>
                          </div> */}
                      </div>
              </div>
              </Reveal>
          </div>
          <div className="col-md-6 xs-hide">
          <Reveal className='onStep d-inline' keyframes={inline} delay={300} duration={1200} triggerOnce>
              <img src="./img/misc/women-with-vr.png" className="img-fluid" alt=""/>
          </Reveal>
          </div>
      </div>
    </div>
);
export default slidermainparticle;