import {useEffect,} from 'react'
import { useDispatch} from 'react-redux'
import {ethers} from 'ethers'
import PETALABI from '../../abis/Petal.json'
import FLOWERABI from '../../abis/Flower.json'
import REWARDABI from '../../abis/Reward.json'
import LOTUSABI from '../../abis/Lotus.json'
import config from '../../config.json'
import Center from './CenterCopyClaimPolyn'
import { loadProvider ,loadNetwork,loadAccount,loadPetal,loadFlower,loadLotus,loadReward} from '../../store/interactions'
import Navbar from './NavbarCopy'
import Balance from './BalanceCopyClaim'
import Markets from './MarketsCopyClaim'
import logo from '../../assets/logo.png'
import {Button, Alert,Container,Row,Col} from 'react-bootstrap'



function App() {
    const dispatch = useDispatch()//puts instore
//fetch metamask account info-------------------
    const loadBlockchainData = async() =>{


        //connecting to the blockchain within loadblockchain data
        // const provider = new ethers.providers.Web3Provider(window.ethereum) THIS IS THE ORIGINAL BUT MOVED. LINE BELOW NEW
        const provider = loadProvider(dispatch)
        dispatch({type:'PROVIDER_LOADED', connection: provider})//dispatches
        // const network = await provider.getNetwork() ORIGINAL
        const network = await loadNetwork(provider,dispatch)
        console.log(network.chainId)
        await loadAccount(provider,dispatch)

        const chainId = await loadNetwork(provider,dispatch)
        //reload page when network changes

        window.ethereum.on('chainChanged',()=>{
            window.location.reload()
        })
        // await loadAccount(provider,dispatch)//loading accounts
         // const accounts = await window.ethereum.request({method: 'eth_requestAccounts'})//OLD VERSION
        // console.log("account",accounts[0])

        //fetch account and balance from metamask when changed
        window.ethereum.on('chainChanged',()=>{
        })


        //Interacting with a smart contract-------------------------@@
        // const petal = new ethers.Contract(provider,config[31337].Petal.address,PETALABI, provider,dispatch)
        //console.log("petal address:",petal.address)
        await loadPetal(provider,config[137].Petal.address,dispatch)
        await loadFlower(provider,config[137].Flower.address,dispatch)
        await loadReward(provider,config[137].Reward.address,dispatch)
        await loadLotus(provider,config[137].Lotus.address,dispatch)




        // const symbol = await petal.balance()
        // console.log("symbol:",symbol)

    } //gets rendered in app.js but must call it with a "useeffect" hook

    useEffect(()=>{ //runs a function
        loadBlockchainData() //must pass in a function
    })


    return (
      <div>

        {/* Navbar */}
        <Navbar/>

      

    
          <Center/>
     
           
         

        
          
      

        {/* Alert */}

      </div>
    );
  }

  export default App;