import {useEffect,} from 'react'
import { useDispatch} from 'react-redux'
import {ethers} from 'ethers'
import PETALABI from '../abis/Petal.json'
import FLOWERABI from '../abis/Flower.json'
import REWARDABI from '../abis/Reward.json'
import LOTUSABI from '../abis/Lotus.json'
import config from '../config.json'
import Center from './Center'
import { loadProvider ,loadNetwork,loadAccount,loadPetal,loadFlower,loadLotus} from '../store/interactions'
import Navbar from './Navbar'
import Balance from './Balance'
import Markets from './Markets'
function App() {
    const dispatch = useDispatch()//puts instore
//fetch metamask account info-------------------
    const loadBlockchainData = async() =>{


        //connecting to the blockchain within loadblockchain data
        // const provider = new ethers.providers.Web3Provider(window.ethereum) THIS IS THE ORIGINAL BUT MOVED. LINE BELOW NEW
        const provider = loadProvider(dispatch)
        dispatch({type:'PROVIDER_LOADED', connection: provider})//dispatches
        // const network = await provider.getNetwork() ORIGINAL
        const network = await loadNetwork(provider,dispatch)
        console.log(network.chainId)
        await loadAccount(provider,dispatch)

        const chainId = await loadNetwork(provider,dispatch)
        //reload page when network changes

        window.ethereum.on('chainChanged',()=>{
            window.location.reload()
        })
        // await loadAccount(provider,dispatch)//loading accounts
         // const accounts = await window.ethereum.request({method: 'eth_requestAccounts'})//OLD VERSION
        // console.log("account",accounts[0])

        //fetch account and balance from metamask when changed
        window.ethereum.on('chainChanged',()=>{
        })


        //Interacting with a smart contract-------------------------@@
        // const petal = new ethers.Contract(provider,config[31337].Petal.address,PETALABI, provider,dispatch)
        //console.log("petal address:",petal.address)
        await loadPetal(provider,config[31337].Petal.address,dispatch)
        await loadFlower(provider,config[31337].Flower.address,dispatch)
        // await loadReward(provider,config[31337].Reward.address,dispatch)
        await loadLotus(provider,config[31337].Lotus.address,dispatch)




        // const symbol = await petal.balance()
        // console.log("symbol:",symbol)

    } //gets rendered in app.js but must call it with a "useeffect" hook

    useEffect(()=>{ //runs a function
        loadBlockchainData() //must pass in a function
    })


    return (
      <div>

        {/* Navbar */}
        <Navbar/>

        <main className='exchange grid'>
          <section className='exchange__section--left grid'>

            {/* Markets */}
        <Markets/>
            {/* Balance */}
        <Balance/>
            {/* Order */}

          </section>
          <section className='exchange__section--right grid'>

            {/* PriceChart */}

            {/* Transactions */}
            <Center/>
            {/* Trades */}

            {/* OrderBook */}

          </section>
        </main>

        {/* Alert */}

      </div>
    );
  }

  export default App;