import React from 'react'
import NavbarCopy from '../components/Component Copies Front Page/NavbarCopy'
import CenterCopy from '../components/Component Copies Front Page/CenterCopy'
import AppCopyStake from '../components/Component Copies Front Page/AppCopyStake'
import {Button, Alert,Container,Row,Col} from 'react-bootstrap'



function MintPetalPage() {return (




        <AppCopyStake></AppCopyStake>

)
}

export default MintPetalPage